@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');

body {
  background: hsl(200, 6%, 12%);
  margin: 0;
  font-family: 'Inter Tight', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: 'Montserrat', sans-serif;
}

.home {
  background-image: url("./Images/acceuil.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  height: 100vh;
}

.layer {
  background-color: hsla(200, 6%, 10%, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.partial-border {
  height: 50%;
  position: relative;
  margin: 20px;
}

.partial-border:after {
  content: '';
  width: 70%;
  height: 1.5px;
  background: rgb(234 88 12);
  position: absolute;
  right: 12%;
  bottom: 0px;
}


.partial-border-big:after {
  content: '';
  width: 200px;
  height: 1.5px;
  background: rgb(234 88 12);
  position: absolute;
  right: 44.5%;
  bottom: 20%;
}


.partial-border-left:after {
  content: '';
  width: 20%;
  height: 4px;
  background: rgb(234 88 12);
  position: absolute;
  left: -30%;
  top: 50%;
}

.pulse {
  animation: pulse-red 2s infinite;
  box-shadow: 0 0 0 0 hsla(var--(color), 0.7);
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 hsla(0, 100%, 100%, 0.7);
  }

  75% {
    box-shadow: 0 0 0 20px hsla(0, 100%, 66%, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.thumbmail-scroller::-webkit-scrollbar {
  width: 100%;
  height: 8px;
}

.thumbmail-scroller::-webkit-scrollbar-track {
  background: hsl(200, 5%, 5%);
}

.thumbmail-scroller::-webkit-scrollbar-thumb {
  background-color: rgb(249 115 22);
  border-radius: 4px;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: hsl(200, 5%, 5%);
}

body::-webkit-scrollbar-thumb {
  background-color: hsl(200, 5%, 20%);
  border-radius: 4px;
}



._t1897 {
  border-radius: .3rem;
}

._19gvi ._t1897 {
  border: 1px solid rgb(249 115 22);
}

._2c50p ._-cAh3 ._t1897 {
  min-height: 100%;
}

._2c50p {
  margin-top: 1rem !important;
  height: 70px !important;
  overflow: visible !important;
}

._-cAh3 {
  min-width: none !important;
  width: 100px !important;
  max-width: none !important;
}

._-LJ2W {
  gap: 1rem;
  /* width: 400px !important; */
}


._2c50p {
  display: flex;
  justify-content: center;
  min-width: 100%;
}

.__JnHV {
  background: hsl(200, 5%, 10%) !important;
  color: rgb(249 115 22);
  border-radius: .3rem;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  background: hsl(200, 5%, 10%);
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 2rem;
  margin-bottom: 10rem;
  margin-top: 2rem;
}

.next {
  color: rgb(226 232 240);
}

.prev {
  color: rgb(226 232 240);
}

.active {
  background: rgb(249 115 22);
  border-radius: 50%;
  /* padding: 8px 18px; */
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numbers:hover {
  cursor: pointer;
  scale: 1.05;
}

.numbers {
  color: rgb(226 232 240);
  ;
}

@media (max-width: 768px) {
  .pagination {
    margin-bottom: 2rem;
  }
}